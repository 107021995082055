/**
 * This file was automatically generated by Strapi.
 * Any modifications made will be discarded.
 */
import seo from "/node_modules/.strapi/vite/deps/@strapi_plugin-seo_strapi-admin.js?v=62009c4f";
import usersPermissions from "/node_modules/.strapi/vite/deps/@strapi_plugin-users-permissions_strapi-admin.js?v=62009c4f";
import { renderAdmin } from "/node_modules/.strapi/vite/deps/@strapi_strapi_admin.js?v=62009c4f";

renderAdmin(document.getElementById("strapi"), {
  plugins: {
    seo: seo,
    "users-permissions": usersPermissions,
  },
});
